<div class="modal-content">
    <div class="modal-header modal-header--bg">
        <h4 class="modal-title w-100">{{ title }}</h4>
    </div>
    
    <div class="modal-body text-center">
        <p class="font-weight-bold">{{ warningMessage }}</p>
        <p class="step-form__title">{{ message }}</p>
        <div class="btn__group text-center">
            <button type="button" class="btn btn-primary btn--md mr_30" 
                (click)="redirectToCorporate()">Logout</button>
        </div>
    </div>
</div>
  