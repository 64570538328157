import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
 
  export const authCodeFlowConfig: AuthConfig = {
    /**
     * The Identity Server's uri.
     */
    issuer: environment.oauthServerUrl,

    /**
     * Defines whether https is required.
     * The default value is remoteOnly which only allows
     * http for localhost, while every other domains need
     * to be used with https.
     */
    requireHttps: environment.requireHttps,

    /**
     * Url of the token endpoint as defined by OpenId Connect and OAuth 2.
     */
    tokenEndpoint:  environment.oauthServerUrl + '/connect/token',
    
    /**
     * The logout url.
     */
    logoutUrl: environment.oauthServerUrl + "/connect/endsession",

    /**
     * An optional second redirectUri where the auth server
     * redirects the user to after logging out.
     */
    postLogoutRedirectUri: environment.oauthRedirectUrl + "/#/sign-out",

    // URL of the SPA to redirect the user to after login
    redirectUri: environment.oauthRedirectUrl + "/#/sign-in",
 
    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'VISA_FIRST_API',
 
    responseType: 'code',
 
    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'openid profile email VISA_FIRST_API offline_access',

    /**
     * Defines whether additional debug information should
     * be shown at the console. Note that in certain browsers
     * the verbosity of the console needs to be explicitly set
     * to include Debug level messages.
     */
    /* showDebugInformation: true */
  };