// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Localhost - Virtual configuration
 * Running port must be 4400 - AFTER REFACTORING LOCALHOST
 */
/* export const environment = {
  refEnv: 'localhost',
  production: false,
  apiVersion: 1,
  version: '0.0.8',
  stripeApiKey: 'pk_test_RyDEFkh9dNHX49KBUimI6ZfQ',
  baseUrl: 'http://192.168.14.95:50056/',
  identityServerUrl: 'https://sso-test.taxback.com/',
  signOutUrl: 'http://staging.visafirst.com/en/',
  // Identity Server configuration
  requireHttps: false, 
  // oauthServerUrl: 'http://192.168.14.95:60000',
  oauthServerUrl: 'https://is-preprod.taxback.cc',
  oauthRedirectUrl: 'http://localhost:4400'
}; */

/* *
 * Localhost - Staging configuration
 * Running port must be 4400 - BEFORE REFACTORING LOCALHOST
 */
// export const environment = {
//   refEnv: 'localhost',
//   production: false,
//   apiVersion: 1,
//   version: '0.0.8',
//   stripeApiKey: 'pk_test_RyDEFkh9dNHX49KBUimI6ZfQ',

//   baseUrl: 'https://api.visafirst.net/',
//   identityServerUrl: 'https://sso-test.taxback.com/',
//   signOutUrl: 'http://staging.visafirst.com/en/',

//   // Identity Server configuration
//   requireHttps: false, 
//   // oauthServerUrl: 'http://192.168.14.95:60000',
//   oauthServerUrl: 'https://is-preprod.taxback.cc',
//   oauthRedirectUrl: 'http://localhost:4400'
// }; 


/**
 * Virtual configuration
 */
/* export const environment = {
  refEnv: '192.168.14.93',
  production: false,
  apiVersion: 1,
  version: '0.0.8',
  stripeApiKey: 'pk_test_RyDEFkh9dNHX49KBUimI6ZfQ',

  baseUrl: 'http://192.168.14.95:50056/',
  identityServerUrl: 'https://sso-test.taxback.com/',
  signOutUrl: 'http://192.168.14.93:34023/',

  // Identity Server configuration
  requireHttps: false, 
  oauthServerUrl: 'https://is-preprod.taxback.cc',
  oauthRedirectUrl: 'http://192.168.14.93:34023'
};
 */
/**
 * Staging configuration - AFTER REFACTORING STAGING
 */
// export const environment = {
//   refEnv: '192.168.14.93',
//   production: false,
//   apiVersion: 1,
//   version: '0.0.8',
//   stripeApiKey: 'pk_test_RyDEFkh9dNHX49KBUimI6ZfQ',

//   baseUrl: 'http://192.168.14.95:50056/',
  
//   identityServerUrl: 'https://sso-test.taxback.com/',
//   signOutUrl: 'http://staging.visafirst.com/en/',

//   // Identity Server configuration
//   requireHttps: false, 
//   oauthServerUrl: 'https://is-preprod.taxback.cc',
//   oauthRedirectUrl: 'http://192.168.14.93:34023'
// };

/**
 * Staging configuration - BEFORE REFACTORING STAGING
 */
/* export const environment = {
  refEnv: '192.168.14.93',
  production: false,
  apiVersion: 1,
  version: '0.0.8',
  stripeApiKey: 'pk_test_RyDEFkh9dNHX49KBUimI6ZfQ',

  baseUrl: 'https://api.visafirst.net/',
  
  identityServerUrl: 'https://sso-test.taxback.com/',
  signOutUrl: 'http://staging.visafirst.com/en/',

  // Identity Server configuration
  requireHttps: false, 
  oauthServerUrl: 'https://is-preprod.taxback.cc',
  oauthRedirectUrl: 'http://192.168.14.93:34023'
};  */

/**
 * AWS configuration
 */
export const environment = {
  refEnv: 'www1.visafirst.com',
  production: true,
  apiVersion: 1,
  version: '0.0.8',
  stripeApiKey: 'pk_live_JHuaycDG4b1ny3roUtK4jglF',
  baseUrl: 'https://api.visafirst.com/',
  identityServerUrl: 'https://sso-test.taxback.com/',
  signOutUrl: 'https://www.visafirst.com/en/',
  // Identity Server configuration
  requireHttps: true,
  oauthServerUrl: 'https://is.taxback.com',
  oauthRedirectUrl: 'https://www1.visafirst.com'
};

