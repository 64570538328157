import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MDBBootstrapModulesPro, MDBBootstrapModule, ToastModule } from 'ng-uikit-pro-standard';
//import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './modules/app-routing.module';
import { SharedModule } from '../shared/shared.module';

import { HomeComponent } from './components/home/home.component';
import { RootComponent } from './components/root/root.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './modules/guards/auth.guard';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { AccessDeniedMessageComponent } from './components/access-denied-message/access-denied-message.component';
import { LoaderInterceptorService } from '../shared/interceptors/loader-interceptor.service';
import { NumenclaturesCacheInterceptor } from '../shared/interceptors/numenclatures-cache.interceptor';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory() : OAuthStorage {
  return localStorage
}

@NgModule({
  declarations: [
    HomeComponent,
    RootComponent,
    SignInComponent,
    SignOutComponent,
    AccessDeniedMessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    OAuthModule.forRoot(),
    ToastModule.forRoot(),
    SharedModule,
    BsDatepickerModule.forRoot(),
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthService, 
    AuthGuard,
    /**
     * Use it to can OAuth to store tokens in local storage
     * Remove it if you want to store OAuth tokens in session storage
     */
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NumenclaturesCacheInterceptor, multi: true},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: 'googleTagManagerId',  useValue: 'GTM-TJPZ9CQ'},
  ],
  bootstrap: [RootComponent]
})
export class AppModule { }
